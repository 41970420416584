import React from 'react';
import EventList from '../components/calendar/EventList';
import { withNamespaces } from 'react-i18next';

function Calendar({ events, t, language }) {
	return (
		<div className="calendar">
			<div className="calendar-grid">
				<h2 className="normal-headline">{t('calendar')}</h2>
				<EventList events={events} language={language} />
			</div>
		</div>
	);
}
export default withNamespaces()(Calendar);
