import React from 'react';
import moment from 'moment';

export default function StartEvent({ event, language }) {
	const { begin, title, location, zeitspanne, date } = event.fields;
	const isSpan = moment(zeitspanne).hour() != 1;
	const isDateZero = moment(date).hour() === 0;
	return (
		<div className="first-view">
			<h3>{language ? moment(begin).format('DD.MM.YYYY') : moment(begin).format('MM-DD-YYYY')} {isSpan ? '' : ` - `} <br /> {isSpan ? '' : language ? moment(zeitspanne).format('DD.MM.YYYY') : moment(zeitspanne).format('MM-DD-YYYY')}</h3>
			<div className="event-information">
				<h4>{title}</h4>
				<h5>
				{location} {isDateZero ? '' : '|'} {isDateZero ? '' : language ? moment(begin).format('HH:mm') : moment(begin).format('h:mm A')}
				</h5>
			</div>
		</div>
	);
}
