import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const gsap = window.gsap;

  
window.onload = function() {
		gsap.to('.fade-out-image', { opacity: 0, duration: .6, delay: .5 });
		gsap.to('.fade-out', { opacity: 0, duration: .4, delay: 1.8 });
		gsap.to('.fade', { visibility: 'hidden', delay: 2.4 });
		gsap.from('.textbio', { opacity: 0, duration: 1, y: -50, delay: 0.8 });
	gsap.to('.textbio', { opacity: 1, duration: 1.2, y: 0, delay: 1.7 });
	gsap.to('.animation-bio', {
		background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0.595) 100%)',
		duration: 1.3,
		delay: 0.9,
		ease: "power1.in",
	});
	gsap.to('.animation-bi', {
		duration: 1.3,
		delay: 0.4,
		backgroundSize:"200%",
		backgroundPosition: "65% 0px",
		ease: "power1.in"
	});
	//document.getElementById('checkloader').className = 'loaded';
};

$('.burger, .navbar .nav-links .li').click(function() {
	$('.navbar .nav-content .nav-links').toggleClass('nav-active');
	$('.navbar .nav-content .nav-links .normal-link').toggleClass('nav-active');
	$('.navbar').toggleClass('nav-active');
	$(this).toggleClass('nav-active');
});


$('.navbar-media .bottom-bar ul li a').on('click', function(e) {
	if(this.hash !== ''){
	  e.preventDefault();
	  const hash = this.hash;

	  $('html, body').animate({
		scrollTop: $(hash).offset().top
	  }, 800);
	}
  });

  $(document).ready(function(){
	  $(".fade-out").addClass("important");
	  $("body").addClass("body-loaded");
  });

  const tl = gsap.timeline({
	defaults: {
		ease: "power1.out"
	}
});

tl.to('.text', {
	y: "0%",
	duration: 3.3,
	stagger: 0.6,
	delay: 0.23
});
tl.to('.slider', {
	y: "-100%",
	duration: 1,
	delay: 0.4
});
tl.to('.intro', {
	y: "-100%",
	duration: 0.6
}, "-=1");

/* 
$(document).ready(function() {


    $('#arrow2R').click(function(){
		console.log('ok')
		$('.navbar-media .bottom-bar ul li').animate( { scrollLeft: '-=30' });
  });

    $('#arrow2L').click(function(){
		$('#container-scroll ul').animate( { scrollLeft: '-=30' });
    });

});

*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
