import React from 'react';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { ClickAwayListener } from '@material-ui/core';
import i18n from '../../i18n';

function NavBlack({ t, language, onToggle }) {
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	const toggleGerman = () => {
		changeLanguage('de');
		onToggle(true);
	};

	const toggleEnglish = () => {
		changeLanguage('en');
		onToggle(false);
	};

	return (
		<div className="navbar navbar-black">
			<div className="nav-content">
				<div className="logo-mobile">
					<a href="/">
						<h1>Dominik Wagner</h1>
						<p>{t('Kontrabass')}</p>
					</a>
				</div>
				<ul className="nav-links">
					<li className="normal-link">
						<a href="/press">{t('Bio/Fotos')}</a>
					</li>
					<li className="normal-link">
						<a href="/media">{t('Bassothek')}</a>
					</li>
					<li className="logo-desktop">
						<a href="/">
							<h1>Dominik Wagner</h1>
							<p>{t('Kontrabass')}</p>
						</a>
					</li>
					<li className="normal-link">
						<a href="/calendar">{t('calendar')}</a>
					</li>
					<li className="normal-link">
						<a href="/contact">{t('contact')}</a>
					</li>
					<li className="normal-link">
						<div class="language-switch language-switch-mobile">
							<a className={language ? 'active-language' : ''} onClick={toggleGerman}>
								DE
							</a>
							<a class={language ? '' : 'active-language'} onClick={toggleEnglish}>
								EN
							</a>
						</div>
					</li>
				</ul>
				<div className="burger">
					<div className="line line-1" />
					<div className="line line-2" />
					<div className="line line-3" />
				</div>
				<div class="language-switch language-switch-desktop">
					<a className={language ? 'active-language' : ''} onClick={toggleGerman}>
						DE
					</a>
					<a class={language ? '' : 'active-language'} onClick={toggleEnglish}>
						EN
					</a>
				</div>
			</div>
		</div>
	);
}

export default withNamespaces()(NavBlack);
