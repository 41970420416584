import React from 'react'
import DreherLogo from '../../assets/Schriftzug-dm.png'
import { withNamespaces } from 'react-i18next'

function FooterBlack({ t }) {
  return (
    <footer className='margin-footer footer-black'>
      <div className='footer-section'>
        <div className='item item-1'>
          <a href='/imprint'>{t('imprint')}</a>
          <span>,&nbsp;</span>
          <a href='/privacy'>{t('data & privacy')}</a>
        </div>
        <div className='item item-2'>
          <p>©{new Date().getFullYear()} by Dominik Wagner</p>
        </div>
        <div className='item item-3'>
          <span>{t('created by')}</span>
          <a
            href='https://www.dreher-media.de/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={DreherLogo} alt='logo dreher.media' />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default withNamespaces()(FooterBlack)
