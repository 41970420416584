import React from 'react';
import BioContent from '../components/bio/BioContent';
import { withNamespaces } from 'react-i18next';

function Press({ biography, language, t }) {
	return (
		<div className="wrapper" id="wrapper">
			<div className="dark-bg">
				<section className="animation-bi">
					<section className="animation-bio" id="animation-bio">
						<div className="trigger">
							<div className="textbio">
								<div className="inside-bio">
									<div className="item">
										{biography.slice(0, 1).map((bio, index) => {
											return <BioContent biography={bio} key={index} language={language} />;
										})}
									</div>

									<div className="item-press">
										<h2>{t('press photos')}</h2>
										<div className="press-grid">
											<div className="item-grid-press">
												<img src="./img/Dominik_Wagner-Julia Wesely, Fotocredit Wiener Konzerthaus2-web.jpg" />
												<a 
													download="Dominik_Wagner-Julia Wesely, Fotocredit Wiener Konzerthaus2.jpg"
													href="./img/Dominik_Wagner-Julia Wesely, Fotocredit Wiener Konzerthaus2.jpg"
													title="copyright-julia-wesely-1"
													className="download-btn"
												>
													{t('download')}
												</a>
												
											</div>
											<div className="item-grid-press">
												<img src="./img/Dominik Wagner-Copyright Maria Frodl 1-web.jpg" />
												<a
													download="Dominik Wagner-Copyright Maria Frodl 1.jpg"
													href="./img/Dominik Wagner-Copyright Maria Frodl 1.jpg"
													title="copyright-maria-frodl-1"
													className="download-btn"
												>
													{t('download')}
												</a>
											</div>
											
											<div className="item-grid-press">
												<img src="./img/Dominik Wagner-Copyright Maria Frodl 3-web.jpg" />
												<a
													download="Dominik Wagner-Copyright Maria Frodl 3.jpg"
													href="./img/Dominik Wagner-Copyright Maria Frodl 3.jpg"
													title="copyright-maria-frodl-3"
													className="download-btn"
												>
													{t('download')}
												</a>
											</div>
											<div className="item-grid-press">
												<img src="./img/Dominik Wagner - Copyright Maria Frodl 2-web.jpg" />
												<a
													download="Dominik Wagner - Copyright Maria Frodl 2.jpg"
													href="./img/Dominik Wagner - Copyright Maria Frodl 2.jpg"
													title="copyright-maria-frodl-2"
													className="download-btn"
												>
													{t('download')}
												</a>
											</div>
											<div className="item-grid-press">
												<img src="./img/Dominik Wagner - Copyright Rania Moslam-web.jpg" />
												<a
													download="Dominik Wagner - Copyright Rania Moslam.jpg"
													href="./img/Dominik Wagner - Copyright Rania Moslam.jpg"
													title="copyright-rania-moslam"
													className="download-btn"
												>
													{t('download')}
												</a>
											</div>
											<div className="item-grid-press">
												<img src="./img/Dominik_Wagner-Julia Wesely, Fotocredit Wiener Konzerthaus1-web.jpg" />
												<a
													download="Dominik_Wagner-Julia Wesely, Fotocredit Wiener Konzerthaus1.jpg"
													href="./img/Dominik_Wagner-Julia Wesely, Fotocredit Wiener Konzerthaus1.jpg"
													title="copyright-julia-wesely-2"
													className="download-btn"
												>
													{t('download')}
												</a>
											</div>
											
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
			</div>
		</div>
	);
}

export default withNamespaces()(Press);
