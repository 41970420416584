import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <span className="bold">{children}</span>;

const Text = ({ children }) => <p>{children}</p>;

const options = {
	renderMark: {
		[MARKS.BOLD]: (text) => <Bold>{text}</Bold>
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>
	}
};

export default function BioContent({ biography, language }) {
	const { title, content, titleEnglish, contentEnglish } = biography.fields;
	return (
		<React.Fragment>
			<h2>{language ? title : titleEnglish}</h2>
			<div className='bio-wrapper-text'>
			<React.Fragment>
				{language ? (
					documentToReactComponents(content, options)
				) : (
					documentToReactComponents(contentEnglish, options)
				)}
			</React.Fragment>
			</div>
		</React.Fragment>
	);
}
