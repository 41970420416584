import React, { useState } from 'react';
import moment from 'moment';
import EventModal from './EventModal';
import { withNamespaces } from 'react-i18next';

function Event({ event, language, t }) {
	const [ eventModalOpen, toggleEventModal ] = useState(false);
	const { title, subtitle, date, begin, tickets, location, zeitspanne } = event.fields;
	const eventDate = moment(date).format('DD/MM/YYYY-hh:mm:ss');
	const today = moment().format('DD/MM/YYYY-hh:mm:ss');
	const isInThePast = moment(eventDate).diff(today) < 0;
	const isSpan = moment(zeitspanne).hour() != 1;
	const isDateZero = moment(date).hour() === 0;
	return (
		<div className="item-calendar">
			<div className="first-view">
				<h3>{language ? moment(begin).format('DD.MM.YYYY') : moment(begin).format('MM-DD-YYYY')} {isSpan ? '' : ` - `} <br /> {isSpan ? '' : language ? moment(zeitspanne).format('DD.MM.YYYY') : moment(zeitspanne).format('MM-DD-YYYY')}</h3>
				<div className="event-information">
					<h4>{title}</h4>
					<h5>
						{location} {isDateZero ? '' : '|'} {isDateZero ? '' : language ? moment(begin).format('HH:mm') : moment(begin).format('h:mm A')}
					</h5>
				</div>
				<button className="view-more" onClick={() => toggleEventModal(true)}>
					{t('more information')}
				</button>
				<EventModal
					event={event}
					isDateZero={isDateZero}
					isSpan={isSpan}
					eventModalOpen={eventModalOpen}
					language={language}
					onClose={() => toggleEventModal(false)}
				/>
			</div>
		</div>
	);
}

export default withNamespaces()(Event);
