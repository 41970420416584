import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

function NavMedia({ onToggle, language, t }) {
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	const toggleGerman = () => {
		changeLanguage('de');
		onToggle(true);
	};

	const toggleEnglish = () => {
		changeLanguage('en');
		onToggle(false);
	};

	const left = () => {
		var submenuContent = document.querySelector('.page-submenu-content');

		console.log('baz');
		submenuContent.scrollBy(100, 0);
	};
	const right = () => {
		var submenuContent = document.querySelector('.page-submenu-content');

		console.log('baz');
		submenuContent.scrollBy(-100, 0);
	};
	return (
		<div className="navbar navbar-black navbar-media">
			<div className="nav-content">
				<div className="logo-mobile">
					<a href="/">
						<h1>Dominik Wagner</h1>
						<p>{t('Kontrabass')}</p>
					</a>
				</div>
				<ul className="nav-links">
				<li className="normal-link">
						<a href="/press">{t('Bio/Fotos')}</a>
					</li>
					<li className="normal-link">
						<a href="/media">{t('Bassothek')}</a>
					</li>
					<li className="logo-desktop">
						<a href="/">
							<h1>Dominik Wagner</h1>
							<p>{t('Kontrabass')}</p>
						</a>
					</li>
					<li className="normal-link">
						<a href="/calendar">{t('calendar')}</a>
					</li>
					<li className="normal-link">
						<a href="/contact">{t('contact')}</a>
					</li>
					<li className="normal-link">
						<div class="language-switch language-switch-mobile">
							<a className={language ? 'active-language' : ''} onClick={toggleGerman}>
								DE
							</a>
							<a className={language ? '' : 'active-language'} onClick={toggleEnglish}>
								EN
							</a>
						</div>
					</li>
				</ul>
				<div className="burger">
					<div className="line line-1" />
					<div className="line line-2" />
					<div className="line line-3" />
				</div>
				<div class="language-switch language-switch-desktop">
					<a className={language ? 'active-language' : ''} onClick={toggleGerman}>
						DE
					</a>
					<a className={language ? '' : 'active-language'} onClick={toggleEnglish}>
						EN
					</a>
				</div>
			</div>
			<div className="bottom-bar">
				<div className="wrapper page-submenu-content" id="container-scroll">
					<div id="arrowR" onClick={left}>
						<svg width="20" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M19.231 17.9276C19.231 17.4393 19.0356 16.9901 18.6646 16.6385L3.1958 1.48227C2.84424 1.15024 2.41455 0.974457 1.90674 0.974457C0.910645 0.974457 0.129395 1.73618 0.129395 2.7518C0.129395 3.24008 0.324707 3.6893 0.637207 4.02133L14.856 17.9276L0.637207 31.8338C0.324707 32.1659 0.129395 32.5956 0.129395 33.1034C0.129395 34.119 0.910645 34.8807 1.90674 34.8807C2.41455 34.8807 2.84424 34.7049 3.1958 34.3534L18.6646 19.2166C19.0356 18.8456 19.231 18.4159 19.231 17.9276Z"
								fill="black"
							/>
						</svg>
					</div>
					<ul id="content">
						<li>
							<a href="#videos">Videos</a>
						</li>
						{/*
						<li>
							<a href="#soundcloud">Soundcloud</a>
						</li>
						*/}
						<li>
							<a href="#disko">
								{t('discography')} & {t('publications')}
							</a>
						</li>
						<li>
							<a href="#social">Social Media</a>
						</li>
					</ul>
					<div id="arrowL" onClick={right}>
						<svg width="20" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M0.5 17.8984C0.5 18.3867 0.675781 18.8164 1.04688 19.1875L16.5352 34.3242C16.8672 34.6758 17.2969 34.8516 17.8047 34.8516C18.8203 34.8516 19.6016 34.0898 19.6016 33.0742C19.6016 32.5664 19.3867 32.1367 19.0742 31.8047L4.85547 17.8984L19.0742 3.99219C19.3867 3.66016 19.6016 3.21094 19.6016 2.72266C19.6016 1.70703 18.8203 0.945312 17.8047 0.945312C17.2969 0.945312 16.8672 1.12109 16.5352 1.45312L1.04688 16.6094C0.675781 16.9609 0.5 17.4102 0.5 17.8984Z"
								fill="black"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
}
export default withNamespaces()(NavMedia);
