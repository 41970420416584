import React from 'react';
import { withNamespaces } from 'react-i18next';

import moment from 'moment';
import { ClickAwayListener } from '@material-ui/core';

function NavStart(language, t,) {
	return (
		<div className="navbar">
			<div className="nav-content">
				<div className="logo-mobile">
					<a href="/">
						<h1>Dominik Wagner</h1>
						<p>{t('Kontrabass')}</p>
					</a>
				</div>
				<ul className="nav-links">
					<li className="normal-link">
						<a href="/press">Presse</a>
					</li>
					<li className="normal-link">
						<a href="/media">Media</a>
					</li>
					<li className="logo-desktop">
						<a href="/">
							<h1>Dominik Wagner</h1>
							<p>{t('Kontrabass')}</p>
						</a>
					</li>
					<li className="normal-link">
						<a href="/calendar">Kalender</a>
					</li>
					<li className="normal-link">
						<a href="/contact">Kontakt</a>
					</li>
					<li className="normal-link">
						<div class="language-switch language-switch-mobile">
							<a>DE</a>
							<a class="active-language">EN</a>
						</div>
					</li>
				</ul>
				<div className="burger">
					<div className="line line-1" />
					<div className="line line-2" />
					<div className="line line-3" />
				</div>
				<div class="language-switch language-switch-desktop">
					<a>DE</a>
					<a class="active-language">EN</a>
				</div>
			</div>
		</div>
	);
}
export default withNamespaces()(NavStart);
