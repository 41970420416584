import React from 'react';

export default function Publication({ publication }) {
	const { title, link } = publication.fields;
	return (
		<a href={link} target="_blank">
			<div className="item-disko">
				<p>{title}</p>
			</div>
		</a>
	);
}
