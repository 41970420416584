import React from 'react';
import Publication from './Publication';

export default function PublicationList({ publications }) {
	console.log(publications);
	return (
		<React.Fragment>
			{publications.map((publication, index) => {
				return <Publication publication={publication} key={index} />;
			})}
		</React.Fragment>
	);
}
