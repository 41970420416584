import './styles/App.scss';
import NavStart from './components/common/NavStart';
import NavBlack from './components/common/NavBlack';
import NavMedia from './components/common/NavMedia';
import Start from './pages/Start';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Press from './pages/Press';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import Footer from './components/common/Footer';
import FooterBlack from './components/common/FooterBlack';
import Contact from './pages/Contact';
import Calendar from './pages/Calendar';
import Media from './pages/Media';
import { client } from './client';
import React, { useEffect, useState } from 'react';
import i18n from './i18n';
import CookieConsent, { Cookies } from 'react-cookie-consent';


function App() {
	const [ events, setEvents ] = useState([]);
	const [ biography, setBiography ] = useState([]);
	const [ publications, setPublications ] = useState([]);
	const [ germanLanguage, toggleGermanLanguage ] = useState(true);

	useEffect(() => {
		if (localStorage.getItem('i18nextLng') === 'de') {
			toggleGermanLanguage(true);
		} else {
			toggleGermanLanguage(false);
		}

		client
			.getEntries()
			.then((response) => {
				let events = [];
				let biography = [];
				let publications = [];
				response.items.map((item, index) => {
					let actualItem = item.sys.contentType.sys.id;
					if (actualItem === 'event') {
						events.push(item);
					} else if (actualItem === 'biography') {
						biography.push(item);
					} else if (actualItem === 'publication') {
						publications.push(item);
					}
					return null;
				});
				setEvents(events);
				setBiography(biography);
				setPublications(publications);
			})
			.catch(console.error);
	}, []);

	return (
		<div className="App">
			<Router>
				<Route exact path="/">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<CookieConsent
					location="bottom"
					buttonText="Zustimmen"
					cookieName="cookieDominik"
					style={{ background: '#000001', marginLeft: '0px' }}
					buttonStyle={{
						color: '#000001',
						fontSize: '13px',
						background: '#FFFFFF',
						padding: '7px 45px',
						margin: '20px 25px'
					}}
					expires={150}
				>
					<p style={{ marginBottom: '-10px', marginLeft: '10px', fontSize: '13px' }}>
						Wir und einige unserer Partner setzen zu den in den Cookie-Richtlinien beschriebenen Zwecken<br />{' '}
						Cookies und ähnliche Technologien ein.<br />
						Sie willigen in den Einsatz solcher Technologien ein, indem Sie diesen Hinweis schließen.
					</p>
					<br />{' '}
					<span style={{ fontSize: '13px', color: '#FFFFFF', marginLeft: '10px' }}>
						<a href="/privacy" style={{ fontSize: '13x', color: '#FFFFFF' }}>
							Mehr erfahren
						</a>
					</span>
				</CookieConsent>
					<Start events={events} language={germanLanguage} />
					<FooterBlack language={germanLanguage} />
				</Route>
				<Route exact path="/press">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Press biography={biography} language={germanLanguage} />
					<FooterBlack language={germanLanguage} />
				</Route>
				<Route exact path="/contact">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Contact language={germanLanguage} />
					<FooterBlack language={germanLanguage} />
				</Route>
				<Route exact path="/calendar">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Calendar events={events} language={germanLanguage} />
					<FooterBlack language={germanLanguage} />
				</Route>
				<Route exact path="/media">
					<NavMedia language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Media publications={publications} language={germanLanguage} />
					<FooterBlack language={germanLanguage} />
				</Route>
				<Route exact path="/imprint">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Imprint language={germanLanguage} />
					<FooterBlack language={germanLanguage} />
				</Route>
				<Route exact path="/privacy">
					<NavBlack language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Privacy language={germanLanguage} />
					<FooterBlack language={germanLanguage} />
				</Route>
			</Router>
		</div>
	);
}

export default App;
